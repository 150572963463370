export class LocalStorage {
	static get<T extends LocalStorageInfo>(key: LocalStorageKey) {
		const stringStorage = localStorage.getItem(key);
		return parseLocalStorage(stringStorage) as T;
	}

	static set(key: LocalStorageKey, value: LocalStorageInfo) {
		localStorage.setItem(key, JSON.stringify(value));
	}

	static clear() {
		localStorage.clear();
	}

	static remove(key: LocalStorageKey) {
		localStorage.removeItem(key);
	}
}

function parseLocalStorage(data: string | null) {
	if (data == null || data?.trim() === '') {
		return null;
	}
	return JSON.parse(data);
}
