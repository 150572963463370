import { defineStore } from 'pinia';
import { ref } from 'vue';

export const usePublicConfigStore = defineStore(
	'platform-info',
	() => {
		const publicConfig = ref<PublicConfig>({
			name: '',
			backgroundResourceId: '',
			backgroundColor: [],
			loginBackgroundResourceId: '',
			loginBackgroundColor: [],
			bannerResourceIds: [],
			themes: [],
			shortName: '',
			homeWallId:undefined
		});

		const setPublicConfigData = (config: PublicConfig) => {
			publicConfig.value = config;
		};

		return { publicConfig, setPublicConfigData };
	},
	{ persist: true }
);
