import { ClientStore } from '/@/utils/singleton';
import { isNotDirty } from '/@/utils/tools/map';
import { getEAMStatus } from '/@/api/eam';
import { getVideoPlayerStatus } from '/@/api/system';

/* *
 * 设置资产管理状态
 * 状态设置到单例中
 */
export const setEAMStatus = async () => {
	const eamClientStoreData = ClientStore.get<boolean>('EAM_STATUS');
	if (isNotDirty(eamClientStoreData)) return;

	const res = await getEAMStatus();
	res.success && ClientStore.set('EAM_STATUS', res.data);
};

/* *
 * 设置视频播放器类型
 */
export const setPlayerType = async () => {
	if (isNotDirty(ClientStore.get<VideoPlayerType>('PLAYER_TYPE'))) return;

	const res = await getVideoPlayerStatus();
	res.success && ClientStore.set('PLAYER_TYPE', res.data);
};
