import { cloneDeep, isEmpty as lodashIsEmpty, isString, omit, trim } from 'lodash';

export function objArrayToMap(arr: any[], label: string, value: string) {
	const map = new Map();
	arr.forEach((item) => {
		map.set(item[value], item[label]);
	});

	return map;
}

export function MapToObjArray(map: Map<string, string>, label: string, value: string) {
	const arr: any[] = [];
	map.forEach((val, key) => {
		const obj = {};
		obj[value] = key;
		obj[label] = val;
		arr.push(obj);
	});

	return arr;
}

/* *
 * @description 获取map中某个字段的值
 * @author 苏俊洋
 * @date 2023/3/8 15:23
 */
export function getMapValue<T, K extends keyof T>(map: T, key: K): T[K] {
	return map[key];
}

/**
 * 深度克隆传入对象，然后从克隆对象中删除值为 dirty 的字段，不影响传入对象。
 * @see isDirty
 * @param obj 传入对象，不会被修改
 * @returns 一个新对象
 */
export const omitDirtyField = <T extends Record<string, any>>(obj: T) => {
	// TODO: 需要测试
	const nextObj = cloneDeep(obj);
	return Object.fromEntries(Object.entries(nextObj).filter(([_, v]) => !isDirty(v))) as {
		[K in keyof T as T[K] extends null | undefined ? never : K]: T[K];
	};
};

/**
 * 删除传入对象的dirty字段
 * @see isDirty
 * @param obj 传入对象，会被修改
 */
export const removeDirtyField = <T extends Record<string, any>>(obj: T): void => {
	Object.fromEntries(Object.entries(obj).filter(([_, v]) => !isDirty(v)));
};

/* *
 * 判断对象是否为脏数据
 * @param obj
 * @return boolean
 * @example
 * isDirty(null) // true
 * isDirty(undefined) // true
 * isDirty("") // true
 * isDirty("  ") // true
 * isDirty(true) // false
 * isDirty(false) // false
 */
export const isDirty = <T extends Object | null | undefined>(obj: T) => {
	if (isString(obj)) {
		return trim(obj) === '';
	}

	return obj == null;
};

/* *
 * 判断对象是否为有效数据
 * ```js
 * isNotDirty(null) --> false
 * isNotDirty(undefined) ---> true
 * isNotDirty("") ---> false
 * isNotDirty("  ") ---> false
 * ```
 */
export const isNotDirty = <T extends Object | null | undefined>(obj: T) => !isDirty(obj);

/**
 * 删除对象中的某些字段
 * 对原始对象没有影响
 * ```js
 * const object = { 'a': 1, 'b': '2', 'c': 3 };
 *
 * const omitRes = omits(object, ["a"]);
 *
 * console.log(omitRes); // {b: '2', c: 3}
 * console.log(object); // {a: 1, b: '2', c: 3}
 * ```
 * @param obj 对象
 * @param keys 对象的key组成的数组
 * @returns 返回去除字段后的对象
 */
export const omitObject = <T extends Object, K extends keyof T>(obj: T, keys: K[]) => {
	return omit<T, K>(obj, keys);
};

/**
 * 判断对象或者数组是否是空的
 * ```js
 * isEmpty(null); // true
 * isEmpty(true); // true
 * isEmpty(1); // true
 * isEmpty([]); // true
 * isEmpty([1, 2, 3]); // false
 * isEmpty({}); // true
 * isEmpty({ 'a': 1 }); // false
 * ```
 * @param data 传入的数据
 */
export const isEmpty = <T extends Object>(data?: T) => lodashIsEmpty(data);

/**
 * 判断对象或者数组是否是非空
 * ```js
 * isNotEmpty(null); // false
 * isNotEmpty(true); // false
 * isNotEmpty(1); // false
 * isNotEmpty([]); // false
 * isNotEmpty([1, 2, 3]); // true
 * isNotEmpty({}); // false
 * isNotEmpty({ 'a': 1 }); // true
 * ```
 * @param data 传入的数据
 */
export const isNotEmpty = <T extends Object>(data?: T) => !isEmpty(data);

// TODO: Object.keys(treeMap).includes("details")

// TODO: Object.assign(deviceQueryParam.value, current, size);
