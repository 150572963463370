import axios, { AxiosRequestConfig } from 'axios';
import { ElMessage } from 'element-plus';

class MyResponse<T> implements ResponseData<T> {
	data: T;
	status: number;
	success: boolean;

	orFail(failData: T): T {
		return this.success ? this.data : failData;
	}
}

const instance = axios.create({
	// baseURL: API_PREFIX,
	/**
	 * 跨域请求是否提供凭据信息(cookie、HTTP认证及客户端SSL证明等)
	 * 也可以简单的理解为，当前请求为跨域类型时是否在请求中协带cookie。
	 * 参考https://www.jianshu.com/p/af1fc0fab4c5
	 */
	withCredentials: true,
	headers: {},
	// 请求超时时间为10秒
	timeout: 10000,
});

instance.interceptors.request.use(
	(config) => {
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

instance.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		const status = error.response.status;
		if (status === 401) {
			MsgBox(error);
			window.location.replace('/login');
		} else if (status === 600) {
			window.location.replace('/license');
		} else if (error.response.data.message === error.response.data.error) {
			ElMessage({
				message: error.response.data.message,
				type: 'error',
				duration: 3 * 1000,
			});
		} else if (status === 500) {
			ElMessage.error({ message: '服务端异常', grouping: true });
		} else {
			MsgBox(error);
		}

		return error.response;
	}
);

function MsgBox(error: any) {
	ElMessage({
		grouping: true,
		dangerouslyUseHTMLString: true,
		message: error.response.data.message + '</br>' + error.response.data.error,
		type: 'error',
		duration: 3 * 1000,
	});
}

export const request = async <T>(config: AxiosRequestConfig, successCallback?: Function, failCallback?: Function) => {
	// debugger;
	// if (config.data) {
	// 	config.data = omitDirtyField(config.data);
	// }
	//
	// if (config.params) {
	// 	config.params = omitDirtyField(config.params);
	// }
	const res: any = await instance.request<T, T>(config);

	const data = res.data;
	const status = res.status;
	const result = new MyResponse<T>();
	result.status = status;
	result.data = data;
	result.success = status === 200;

	if (status === 200) {
		successCallback && successCallback(data);
	} else {
		failCallback && failCallback(result);
	}
	return result;
};

export async function get<T>(config: AxiosRequestConfig, successCallback?: Function, failCallback?: Function): Promise<ResponseData<T>> {
	return await request<T>({ ...config, method: 'GET' }, successCallback, failCallback);
}

export async function postJSON<T>(config: AxiosRequestConfig, successCallback?: Function, failCallback?: Function): Promise<ResponseData<T>> {
	return await request(
		{
			...config,
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
		},
		successCallback,
		failCallback
	);
}

export async function postForm<T>(config: AxiosRequestConfig, successCallback?: Function, failCallback?: Function): Promise<ResponseData<T>> {
	return await request({ ...config, method: 'POST' }, successCallback, failCallback);
}

export async function del<T>(config: AxiosRequestConfig, successCallback?: Function, failCallback?: Function): Promise<ResponseData<T>> {
	return await request({ ...config, method: 'DELETE' }, successCallback, failCallback);
}

export async function putJSON<T>(config: AxiosRequestConfig, successCallback?: Function, failCallback?: Function): Promise<ResponseData<T>> {
	return await request(
		{
			...config,
			method: 'PUT',
			headers: { 'Content-Type': 'application/json' },
		},
		successCallback,
		failCallback
	);
}

export async function putTEXT<T>(config: AxiosRequestConfig, successCallback?: Function, failCallback?: Function): Promise<ResponseData<T>> {
	return await request(
		{
			...config,
			method: 'PUT',
			headers: { 'Content-Type': 'text/plain' },
		},
		successCallback,
		failCallback
	);
}

export async function put<T>(config: AxiosRequestConfig, successCallback?: Function, failCallback?: Function): Promise<ResponseData<T>> {
	return await request(
		{
			...config,
			method: 'PUT',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
		},
		successCallback,
		failCallback
	);
}
