import { RouteRecordRaw } from 'vue-router';
import { routePath } from '/@/constant/router';

export const mapRoute: RouteRecordRaw =
	// 系统集成--前台地图
	{
		path: routePath.map,
		name: 'map',
		component: () => import('/@/views/map/index.vue'),
		meta: {
			title: '系统集成',
			roles: ['DEPLOYER', 'MANAGER', 'RUNTIME'],
			isHeadNav: true,
		},
	};
