import { getSystemStatusAPI } from '/@/api/system';
import { LocalStorage } from '/@/utils/localStorage';

export async function setGatewayMode() {
	const res = await getSystemStatusAPI();
	if (res.success) {
		const systemStatus = res.data.activeProfiles;
		LocalStorage.set('SYSTEM_STATUS', systemStatus);
	}
}

export function isGatewayMode() {
	return LocalStorage.get<arrayDataType>('SYSTEM_STATUS')?.includes('gateway') ?? false;
}
