import { ElMessage } from 'element-plus';
import { defineStore } from 'pinia';
import { store } from '..';
import { isLoginAPI, loginAPI, logoutAPI } from '/@/api/user';
import router from '/@/router/index';
import { ClientStore } from '/@/utils/singleton';
import { LocalStorage } from '/@/utils/localStorage';
import { routePath } from '/@/constant/router';

interface UserState {
	isLogined?: boolean;
	userInfo: any;
}

export const useUserStore = defineStore({
	id: 'app-user',
	state: (): UserState => ({ isLogined: false, userInfo: {} }),
	getters: {},
	actions: {
		/**
		 * @description: login
		 */
		async login(params: Partial<User>) {
			const res = await loginAPI(params);
			if (res.success) {
				ElMessage({
					message: '登录成功',
					grouping: true,
					type: 'success',
				});

				this.userInfo = res.data;

				// 存储登录信息到localStorage
				LocalStorage.set('AUTH_INFO', res.data);
			} else {
				ElMessage({
					message: '登录失败',
					grouping: true,
					type: 'warning',
				});
				return false;
			}
			return true;
		},

		/**
		 * @description: isLogin
		 */
		async isLogin() {
			const res: any = await isLoginAPI();
			this.isLogined = res.data;
			return this.isLogined;
		},

		/**
		 * @description: logout
		 */
		async logout() {
			await logoutAPI();
			ClientStore.clear();
			await router.replace(routePath.login);
		},
	},
});

export const useUserStoreWithOut = () => useUserStore(store);
