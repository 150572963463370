import { LocalStorage } from '../localStorage';

/**
 * 更换主题色
 * @param themeName 主题名称
 * @returns
 */
export const changeTheme = (themeName: string) => {
	const ele = document.querySelector('html');
	if (!ele || !themeName) {
		return;
	}
	ele.className = themeName;

	// 切换后，将主题名称存在LocalStorage中
	LocalStorage.set('THEME_CODE', themeName);
};
