import { RouteRecordRaw } from 'vue-router';
import { routePath } from '/@/constant/router';

export const linkageRoute: RouteRecordRaw = {
	path: routePath.linkage.index,
	name: 'linkage.index',
	component: () => import('/@/views/linkage/index.vue'),
	meta: {
		title: '场景联动',
		roles: ['DEPLOYER', 'MANAGER'],
		isHeadNav: true,
		isReload: true,
	},
	redirect: routePath.linkage.config.index,
	children: [
		// 联动记录
		{
			path: routePath.linkage.records,
			name: 'linkage.records',
			component: () => import('/@/views/linkage/records/index.vue'),
			meta: {
				title: '联动记录',
			},
		},
		// 联动规则
		{
			path: routePath.linkage.config.index,
			name: 'linkage.config',
			component: () => import('/@/views/linkage/config/index.vue'),
			meta: {
				title: '联动规则',
			},
			redirect: routePath.linkage.config.list,
			children: [
				// 联动规则列表
				{
					path: routePath.linkage.config.list,
					name: 'linkage.config.list',
					component: () => import('/@/views/linkage/config/list/index.vue'),
					meta: {
						title: '联动规则',
						keepAlive: true,
						isReload: true,
					},
				},
				// 联动规则详情
				{
					path: routePath.linkage.config.detail,
					name: 'linkage.config.detail',
					component: () => import('/@/views/linkage/config/detail/index.vue'),
				},
			],
		},
	],
};
