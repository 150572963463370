import { routePath } from '/@/constant/router';
import { store } from '/@/store/index';
import { usePublicConfigStore } from '/@/store/publicConfig';
import { getCacheSystemPublicConfigAPI } from '/@/api/system';
import { RouteLocationNormalized } from 'vue-router';
import { ElNotification } from 'element-plus';
import { storeToRefs } from 'pinia';
import { LocalStorage } from '../localStorage';
import { changeTheme } from '../theme';

const { home, login } = routePath;
const { setPublicConfigData } = usePublicConfigStore(store);
const { publicConfig } = storeToRefs(usePublicConfigStore());

export const setPublicConfig = async (to: RouteLocationNormalized) => {
	// 更新系统公开配置
	await updatePublicConfig(to);
	// 设置页面背景颜色
	setPlatformBackground(to.path);
	// 设置平台标题
	setPlatformTitle(to);
	// 设置平台主题
	setTheme();
};

/**
 * 根据路径更新系统公开配置
 * @param to
 */
async function updatePublicConfig({ path }: RouteLocationNormalized) {
	if (path === home || path === login) {
		// 更新公开配置
		await refreshPublicConfigStore();
	}
	// TODO: 暂时默认所有的公开配置都从接口取
	await refreshPublicConfigStore();
}

async function refreshPublicConfigStore() {
	// 从缓存中查询系统公开配置
	const res = await getCacheSystemPublicConfigAPI();

	if (!res.success) {
		ElNotification({
			title: '提示',
			message: '平台公开信息缺失，可能导致背景异常',
			type: 'warning',
		});
		return;
	}

	setPublicConfigData(res.data);
}

/**
 * 设置页面背景颜色
 * @param path
 * @returns
 */
function setPlatformBackground(path: string) {
	let backgroundColors: any[] | undefined;

	if (path === routePath.login) {
		if (publicConfig.value?.loginBackgroundColor && publicConfig.value.loginBackgroundColor.length > 0) {
			backgroundColors = publicConfig.value.loginBackgroundColor;
		}
	} else {
		if (publicConfig.value?.backgroundColor && publicConfig.value.backgroundColor.length > 0) {
			backgroundColors = publicConfig.value.backgroundColor;
		}
	}

	if (backgroundColors === undefined) return;

	// 获取样式
	const param: Partial<CSSStyleDeclaration> = {
		background: `linear-gradient(${backgroundColors[0]},${backgroundColors[1]} , ${backgroundColors[2]})`,
	};

	// 设置样式
	setStyle(param);
}

/**
 * 在标签上设置style样式
 * @param style 样式对象
 * @param domId 标签id
 */
function setStyle(style: Partial<CSSStyleDeclaration>, domId: string = 'app') {
	const appEl = document.getElementById(domId);
	if (appEl) {
		for (const [key, value] of Object.entries(style)) {
			appEl.style[key] = value;
		}
	}
}

/**
 * 设置平台呢标题
 * @param to
 */
function setPlatformTitle(to: RouteLocationNormalized) {
	document.title = '系统集成平台';
	to.meta.title = `系统集成平台-${publicConfig.value?.name ?? ''}`;
	document.title = to.meta.title as string;
}

/**
 * 设置主题
 * 如果LocalStorage中有主题，就用LocalStorage设置的主题
 * 如果没有，就使用默认主题
 */
function setTheme() {
	const themeName = LocalStorage.get<string>('THEME_CODE');
	themeName ? changeTheme(themeName) : changeTheme('default');
}
