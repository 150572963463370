import { RouteRecordRaw } from 'vue-router';
import { routePath } from '/@/constant/router';

const { engine } = routePath;
// 工程实施
export const engineRoute: RouteRecordRaw = {
	path: engine.index,
	name: 'engine.index',
	component: () => import('/@//views/engine/index.vue'),
	meta: {
		title: '工程实施',
		roles: ['DEPLOYER', 'MANAGER', 'GATEWAY'],
		isHeadNav: true,
	},
	redirect: engine.user,
	children: [
		// 用户管理
		{
			path: engine.user,
			name: 'engine.user',
			component: () => import('/@/views/engine/user/index.vue'),
			meta: {
				title: '用户管理',
				roles: ['DEPLOYER', 'MANAGER'],
			},
		},
		// 菜单管理
		{
			path: engine.menu,
			name: 'engine.menu',
			component: () => import('/@/views/engine/menu/index.vue'),
			meta: {
				title: '菜单管理',
				roles: ['DEPLOYER'],
			},
		},
		// 设备列表
		{
			path: engine.engine_device_list,
			name: 'engine.engine_device_list',
			component: () => import('/@/views/eam/smart/list/index.vue'),
			meta: {
				title: '设备绑点',
				roles: ['MANAGER'],
			},
		},
		// 设备详情
		{
			path: engine.engine_device_detail,
			name: 'engine.engine_device_detail',
			component: () => import('/@/views/eam/smart/detail/index.vue'),
			meta: {
				title: '设备详情',
				roles: ['DEPLOYER', 'MANAGER', 'RUNTIME'],
			},
		},
		// 设备统计
		{
			path: engine.engine_device_statistic,
			name: 'engine.engine_device_statistic',
			component: () => import('/@/views/eam/smart/deviceStatistic/index.vue'),
			meta: {
				title: '设备统计',
				roles: ['MANAGER'],
			},
		},
		// 外部数据库
		{
			path: engine.external_database,
			name: 'engine.external_database',
			component: () => import('/@/views/engine/externalDataBase/index.vue'),
			meta: {
				title: '外部数据库',
				roles: ['DEPLOYER'],
			},
		},
		// 设备模型
		{
			path: engine.device_model.index,
			name: 'engine.device_model.index',
			component: () => import('/@/views/engine/deviceModel/index.vue'),
			meta: {
				title: '设备模型',
				roles: ['DEPLOYER'],
			},
			redirect: engine.device_model.list,
			children: [
				// 设备模型列表
				{
					path: engine.device_model.list,
					name: 'engine.device_model.list',
					component: () => import('/@/views/engine/deviceModel/modelList/index.vue'),
					meta: {
						title: '设备模型',
						roles: ['DEPLOYER'],
					},
				},
				{
					path: engine.device_model.detail.index,
					name: 'engine.device_model.detail.index',
					component: () => import('/@/views/engine/deviceModel/modelDetail/index.vue'),
					redirect: engine.device_model.detail.ability_model_list,
					children: [
						// 设备模型详情--规则
						{
							path: engine.device_model.detail.rules,
							name: 'engine.device_model.detail.rules',
							component: () => import('/@/views/engine/deviceModel/modelDetail/rules/index.vue'),
							meta: {
								title: '规则',
								roles: ['DEPLOYER'],
							},
						},
						// 设备能力模型列表
						{
							path: engine.device_model.detail.ability_model_list,
							name: 'engine.device_model.detail.ability_model_list',
							component: () => import('/@/views/engine/deviceModel/modelDetail/abilityModelList/index.vue'),
							meta: {
								title: '设备能力模型列表',
								roles: ['DEPLOYER'],
							},
						},
					],
				},
			],
		},
		// 设备
		{
			path: engine.device.index,
			name: 'engine.device.index',
			component: () => import('/@/views/engine/device/index.vue'),
			meta: {
				title: '设备',
				roles: ['DEPLOYER'],
			},
			redirect: engine.device.list,
			children: [
				// 设备平台事件列表
				{
					path: engine.device.events,
					name: 'engine.device.events',
					component: () => import('/@/views/engine/device/events/index.vue'),
					meta: {
						title: '设备事件',
						roles: ['DEPLOYER'],
					},
				},
				// 设备列表
				{
					path: engine.device.list,
					name: 'engine.device.list',
					component: () => import('/@/views/engine/device/list/index.vue'),
					meta: {
						title: '设备绑点',
						roles: ['DEPLOYER'],
					},
				},
				// 设备详情--设备能力
				{
					path: engine.device.device_ability,
					name: 'engine.device.device_ability',
					component: () => import('/@/views/engine/device/detail/index.vue'),
					meta: {
						title: '设备能力',
						roles: ['DEPLOYER'],
					},
				},
			],
		},
		// 网关
		{
			path: engine.gateway,
			name: 'engine.gateway',
			component: () => import('/@/views/engine/gateWay/index.vue'),
			meta: {
				title: '点位管理',
				roles: ['GATEWAY'],
			},
		},
		// HTTP推送
		{
			path: engine.http_url,
			name: 'engine.http_url',
			component: () => import('/@/views/engine/httpPush/index.vue'),
			meta: {
				title: 'HTTP推送',
				roles: ['GATEWAY'],
			},
		},
		// 设备组
		{
			path: engine.device_group,
			name: 'engine.device_group',
			component: () => import('/@/views/engine/deviceGroup/index.vue'),
			meta: {
				title: '空间位置',
				roles: ['DEPLOYER'],
			},
		},
		// 驱动仓库
		{
			path: engine.driver.index,
			name: 'engine.driver.index',
			component: () => import('/@/views/engine/driver/index.vue'),
			meta: {
				title: '驱动仓库',
				roles: ['DEPLOYER', 'GATEWAY'],
			},

			redirect: engine.driver.list,
			children: [
				// 驱动仓库--列表
				{
					path: engine.driver.list,
					name: 'engine.driver.list',
					component: () => import('/@/views/engine/driver/list/index.vue'),
					meta: {
						title: '驱动仓库',
						roles: ['DEPLOYER', 'GATEWAY'],
					},
				},
				// 驱动详情
				{
					path: engine.driver.detail.index,
					name: 'engine.driver.detail.index',
					component: () => import('/@/views/engine/driver/detail/index.vue'),

					redirect: engine.driver.detail.ability,
					children: [
						{
							path: engine.driver.detail.ability,
							name: 'engine.driver.detail.ability',
							component: () => import('/@/views/engine/driver/detail/ability/index.vue'),
							meta: {
								title: '首页',
								roles: ['DEPLOYER'],
							},
						},
						{
							path: engine.driver.detail.init_param_model,
							name: 'engine.driver.detail.init_param_model',
							component: () => import('/@/views/engine/driver/detail/initParamModel/index.vue'),
							meta: {
								title: '首页',
								roles: ['DEPLOYER'],
							},
						},
						{
							path: engine.driver.detail.property_bind_rule_model,
							name: 'engine.driver.detail.property_bind_rule_model',
							component: () => import('/@/views/engine/driver/detail/propertyBindRuleModel/index.vue'),
							meta: {
								title: '首页',
								roles: ['DEPLOYER'],
							},
						},
					],
				},
			],
		},
		// 驱动实例
		{
			path: engine.driver_instance,
			name: 'engine.driver_instance',
			component: () => import('/@/views/engine/driverInstance/index.vue'),
			meta: {
				title: '驱动实例',
				roles: ['DEPLOYER', 'GATEWAY'],
			},
		},
		// 系统管理
		{
			path: engine.system,
			name: 'engine.system',
			component: () => import('/@/views/engine/system/index.vue'),
			meta: {
				title: '系统管理',
				roles: ['DEPLOYER'],
			},
		},
		// 首页配置
		{
			path: engine.home_edit,
			name: 'engine.home_edit',
			component: () => import('/@/views/engine/homeEdit/index.vue'),
			meta: {
				title: '首页配置',
				roles: ['DEPLOYER'],
			},
		},
		// 地图管理
		{
			path: engine.map,
			name: 'engine.map',
			component: () => import('/@/views/engine/map/index.vue'),
			meta: {
				title: '可视化地图',
				roles: ['DEPLOYER'],
			},
		},
	],
};
