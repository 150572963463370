import { RouteRecordRaw } from 'vue-router';
import { routePath } from '/@/constant/router';

export const platformEventRoute: RouteRecordRaw = {
	path: routePath.platform_event,
	name: 'platform_event',
	component: () => import('/@/views/platformEvent/index.vue'),
	meta: {
		title: '平台事件',
		roles: ['DEPLOYER', 'MANAGER', 'RUNTIME'],
		isHeadNav: true,
	},
};
