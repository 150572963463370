import { LocalStorage } from '/@/utils/localStorage';
import { logoutAPI } from '/@/api/user';

/**
 * 验证LocalStorage中是否有登录信息，如果没有，就退出登录
 */
export const loginAuth = async () => {
	const authInfo = LocalStorage.get<User>('AUTH_INFO');
	if (authInfo == null) {
		await logoutAPI();
	}
};
