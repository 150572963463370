import { RouteRecordRaw } from 'vue-router';
import { routePath } from '/@/constant/router';

const { tv_wall } = routePath;
// 电视墙
export const tvWallRoute: RouteRecordRaw = {
	path: tv_wall.index,
	name: 'tv_wall.index',
	component: () => import('/@/views/tvWall/tvManage/index.vue'),
	redirect: routePath.tv_wall.manage,
	meta: {
		title: '电视墙',
		roles: ['DEPLOYER', 'MANAGER', 'RUNTIME'],
		isHeadNav: true,
	},
	children: [
		{
			path: tv_wall.manage,
			name: 'tv_wall.manage',
			component: () => import('/@/views/tvWall/tvManage/index.vue'),
		},
	],
};
