export class ClientStore {
	static store = new Map<ClientStoreKey, StoreDataType>();

	private constructor() {}

	static set(key: ClientStoreKey, info: StoreDataType) {
		ClientStore.store.set(key, info);
	}

	static get<T extends StoreDataType>(key: ClientStoreKey) {
		return ClientStore.store.get(key) as T | undefined;
	}

	static remove(key: ClientStoreKey) {
		return ClientStore.store.delete(key);
	}

	static clear() {
		ClientStore.store.clear();
	}
}
