import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import '/@/styles/index.scss';
import 'element-plus/theme-chalk/src/message.scss';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import 'dayjs/locale/en';
import { createApp } from 'vue';
import App from './app.vue';
import router from './router';
import '/@/router/permission';
import { setupStore } from '/@/store';
import dayjsZhCn from 'dayjs/locale/zh-cn';
import dayjs from 'dayjs';

dayjs.locale(dayjsZhCn);

const app = createApp(App);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
	app.component(key, component);
}

// 使用饿了么UI
app.use(ElementPlus, {
	locale: zhCn,
});

// 使用store
setupStore(app);
// 使用路由
app.use(router);
app.mount('#app');