const route_base_path = process.env.VUE_APP_ROUTE_BASE_URL;
const engine_base_path = `${route_base_path}/engine`;

export const routePath = {
	// 首页
	home: '/home',
	// 登录页
	login: '/login',
	// 3d可视化
	three_view: '/3d_view',
	// 授权管理
	license: '/license',
	// 基础页面，带头部
	base_path: `/${route_base_path}`,
	// 系统集成
	map: `/${route_base_path}/map`,
	// 平台事件
	platform_event: `/${route_base_path}/platform_event`,
	// 场景联动
	linkage: {
		index: `/${route_base_path}/linkage`,
		config: {
			// 联动规则
			index: `/${route_base_path}/linkage/config`,
			// 联动规则列表
			list: `/${route_base_path}/linkage/config/list`,
			// 联动规则详情
			detail: `/${route_base_path}/linkage/config/detail`,
		},
		// 联动记录
		records: `/${route_base_path}/linkage/records`,
	},
	// 文档资料
	file_management: `/${route_base_path}/file_management`,
	// 工程实施
	engine: {
		index: `/${engine_base_path}`,
		// 用户管理
		user: `/${engine_base_path}/user`,
		// 菜单管理
		menu: `/${engine_base_path}/menu`,
		// 设备列表
		engine_device_list: `/${engine_base_path}/engine_device_list`,
		// 设备详情
		engine_device_detail: `/${engine_base_path}/detail`,
		// 设备统计
		engine_device_statistic: `/${engine_base_path}/engine_device_statistic`,
		// 外部数据库
		external_database: `/${engine_base_path}/external_database`,
		// 设备模型
		device_model: {
			index: `/${engine_base_path}/device_model`,
			// 列表
			list: `/${engine_base_path}/device_model/list`,
			detail: {
				index: `/${engine_base_path}/device_model/detail`,
				// 设备模型详情--规则
				rules: `/${engine_base_path}/device_model/detail/rules`,
				// 设备能力模型列表
				ability_model_list: `/${engine_base_path}/device_model/detail/ability_model_list`,
			},
		},
		// 设备
		device: {
			index: `/${engine_base_path}/device`,
			// 设备列表
			list: `/${engine_base_path}/device/list`,
			// 设备平台事件列表
			events: `/${engine_base_path}/device/events`,
			// 设备详情--设备能力
			device_ability: `/${engine_base_path}/device/device_ability`,
		},
		// 网关
		gateway: `/${engine_base_path}/gateway`,
		// HTTP推送
		http_url: `/${engine_base_path}/http_url`,
		// 设备组
		device_group: `/${engine_base_path}/device_group`,
		// 驱动仓库
		driver: {
			index: `/${engine_base_path}/driver`,
			// 驱动列表
			list: `/${engine_base_path}/driver/list`,
			// 驱动详情
			detail: {
				index: `/${engine_base_path}/driver/detail`,
				// 驱动能力
				ability: `/${engine_base_path}/driver/detail/driver_ability`,
				// 初始化模型
				init_param_model: `/${engine_base_path}/driver/detail/init_param_model`,
				// 绑定规则
				property_bind_rule_model: `/${engine_base_path}/driver/detail/property_bind_rule_model`,
			},
		},
		// 驱动实例
		driver_instance: `/${engine_base_path}/driver_instance`,
		// 系统管理
		system: `/${engine_base_path}/system`,
		// 首页配置
		home_edit: `/${engine_base_path}/home_edit`,
		// 地图管理
		map: `/${engine_base_path}/map`,
	},
	// 资产管理
	eam: {
		index: `/${route_base_path}/eam`,
		// 仪表盘
		dashboard: `/${route_base_path}/eam/dashboard`,
		// 智能设备
		smart: {
			index: `/${route_base_path}/eam/smart`,
			// 智能设备列表
			list: `/${route_base_path}/eam/smart/list`,
			// 历史报表
			detail: `/${route_base_path}/eam/smart/detail`,
			//  设备统计
			statistic: `/${route_base_path}/eam/smart/statistic`,
		},
		// 供应商管理
		provider: `/${route_base_path}/eam/provider`,
		// 部门管理
		department: `/${route_base_path}/eam/department`,
		// 资产台账
		device_extra: `/${route_base_path}/eam/device_extra`,
		// 维保计划
		main_plan: {
			index: `/${route_base_path}/eam/main_plan`,
			// 我工作的
			worker: `/${route_base_path}/eam/main_plan/worker`,
			// 我发起的
			send: `/${route_base_path}/eam/main_plan/send`,
			// 维保提醒
			remind: `/${route_base_path}/eam/main_plan/remind`,
		},
		// 设备巡检
		device_inspection: {
			index: `/${route_base_path}/eam/device_inspection`,
			// 设备巡检--我发起的
			send: `/${route_base_path}/eam/main_plan/device_inspection/send`,
			// 设备巡检--我工作的
			worker: `/${route_base_path}/eam/main_plan/device_inspection/worker`,
		},
		// 工单
		work_order: {
			index: `/${route_base_path}/eam/work_order`,
			// 工单，我工作的
			worker: `/${route_base_path}/eam/work_order/worker`,
			// 工单，我发起的
			send: `/${route_base_path}/eam/work_order/send`,
		},
		// 库存管理
		inventory: {
			index: `/${route_base_path}/eam/inventory`,
			// 库存类目
			category: `/${route_base_path}/eam/inventory/category`,
			// 出库记录
			outcome: `/${route_base_path}/eam/inventory/outcome`,
		},
		// 用户工作日报
		report: `/${route_base_path}/eam/report`,
		// 站内信
		notify: `/${route_base_path}/eam/notify`,
	},
	tv_wall: {
		index: `/${route_base_path}/tv_wall`,
		manage: `/${route_base_path}/tv_wall/manage`,
		display: `/${route_base_path}/tv_wall/display`,
	},
};
