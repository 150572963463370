import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { routePath } from '../constant/router';
import { LocalStorage } from '../utils/localStorage';
import { setPublicConfig } from '../utils/permission/platform';
import { setEAMStatus, setPlayerType } from '../utils/permission/status';
import { loginAuth } from '/@//utils/permission/auth';
import router, { getFlattenRoutes } from '/@/router/index';
import { useUserStoreWithOut } from '/@/store/modules/user';
import { isGatewayMode, setGatewayMode } from '/@/utils/mode';

function setEngineName() {
	const routes = getFlattenRoutes();
	const aimRoute = routes.find((route) => route.path === routePath.engine.index);
	if (!aimRoute) return;
	const userInfo = LocalStorage.get<User>('AUTH_INFO');
	if (!userInfo) return;
	if (!aimRoute.meta) return;

	if (userInfo.userType === 'MANAGER') {
		aimRoute.meta.title = '系统管理';
	} else {
		aimRoute.meta.title = '工程实施';
	}
}

/**
 * @description 全局路由过滤、权限过滤
 */
const userStore = useUserStoreWithOut();

router.beforeEach(async (to: RouteLocationNormalized, _from: RouteLocationNormalized, next: NavigationGuardNext) => {
	await setGatewayMode();

	if (isGatewayMode()) {
		next();
	} else {
		// 本地登录信息验证
		await loginAuth();
		// 设置平台配置信息
		await setPublicConfig(to);

		// 动态列表
		setEngineName();

		const isLogin = await userStore.isLogin();
		// TODO 后期要删掉或后面的判断
		if (isLogin || to.path.includes('3d_view')) {
			//如果已经登录，就跳转到要跳转的页面
			// 放在这里，减少未登录时的多余请求
			// 设置资产管理状态
			await setEAMStatus();
			// 设置视频播放器类型
			await setPlayerType();

			next();
		} else {
			// 如果没有登录，进行下面的逻辑
			if (to.path == '/login') {
				//如果是登录页面路径，就直接next()
				next();
			} else {
				//不然就跳转到登录；
				next('/login');
			}
		}
	}
});
