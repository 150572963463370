import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { routes } from '/@/router/route';

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes: routes,
});

/**
 * 平铺路由
 * @param arr
 */
export function formatFlatteningRoutes(arr: any): RouteRecordRaw[] {
	if (arr.length <= 0) return [];
	for (let i = 0; i < arr.length; i++) {
		if (arr[i].children) {
			arr = arr.slice(0, i + 1).concat(arr[i].children, arr.slice(i + 1));
		}
	}

	return arr;
}

export const getFlattenRoutes = () => formatFlatteningRoutes(routes);

export default router;
