import { RouteRecordRaw } from 'vue-router';
import { routePath } from '/@/constant/router';

const { eam } = routePath;

export const eamRoute: RouteRecordRaw = {
	path: eam.index,
	name: 'eam.index',
	component: () => import('/@/views/eam/index.vue'),
	meta: {
		title: '资产管理',
		roles: ['DEPLOYER', 'MANAGER', 'RUNTIME'],
		isHeadNav: true,
	},
	redirect: eam.dashboard,
	children: [
		// 仪表盘
		{
			path: eam.dashboard,
			name: 'eam.dashboard',
			component: () => import('/@/views/eam/dashboard/index.vue'),
			meta: {
				title: '仪表盘',
				roles: ['DEPLOYER', 'MANAGER', 'RUNTIME'],
			},
		},
		{
			path: eam.smart.index,
			name: 'eam.smart.index',
			component: () => import('/@/views/eam/smart/index.vue'),
			meta: { title: '智能设备', roles: ['DEPLOYER', 'MANAGER', 'RUNTIME'] },
			children: [
				{
					path: eam.smart.list,
					name: 'eam.smart.list',
					component: () => import('/@/views/eam/smart/list/index.vue'),
					meta: { title: '设备列表', roles: ['DEPLOYER', 'MANAGER', 'RUNTIME'] },
				},
				{
					path: eam.smart.statistic,
					name: 'eam.smart.statistic',
					component: () => import('/@/views/eam/smart/deviceStatistic/index.vue'),
					meta: { title: '设备统计', roles: ['DEPLOYER', 'MANAGER', 'RUNTIME'] },
				},
				{
					path: eam.smart.detail,
					name: 'eam.smart.detail',
					component: () => import('/@/views/eam/smart/detail/index.vue'),
					meta: { title: '历史报告' },
				},
			],
		},
		// 供应商
		{
			path: eam.provider,
			name: 'eam.provider',
			component: () => import('/@/views/eam/provider/index.vue'),
			meta: { title: '供应商管理', roles: ['DEPLOYER', 'MANAGER', 'RUNTIME'] },
		},
		// 部门
		{
			path: eam.department,
			name: 'eam.department',
			component: () => import('/@/views/eam/department/index.vue'),
			meta: { title: '部门管理', roles: ['DEPLOYER', 'MANAGER', 'RUNTIME'] },
		},
		// 设备台账
		{
			path: eam.device_extra,
			name: 'eam.device_extra',
			component: () => import('/@/views/eam/deviceExtra/index.vue'),
			meta: { title: '资产台账', roles: ['DEPLOYER', 'MANAGER', 'RUNTIME'] },
		},
		// 维保计划
		{
			path: eam.main_plan.index,
			name: 'eam.main_plan.index',
			component: () => import('/@/views/eam/maintainPlan/index.vue'),
			redirect: eam.main_plan.send,
			meta: { title: '维保计划', roles: ['DEPLOYER', 'MANAGER', 'RUNTIME'] },
			children: [
				{
					path: eam.main_plan.send,
					name: 'eam.main_plan.send',
					component: () => import('/@/views/eam/maintainPlan/send.vue'),
					meta: { title: '我发起的', roles: ['DEPLOYER', 'MANAGER', 'RUNTIME'] },
				},
				{
					path: eam.main_plan.worker,
					name: 'eam.main_plan.worker',
					component: () => import('/@/views/eam/maintainPlan/work.vue'),
					meta: { title: '我执行的', roles: ['MANAGER', 'DEPLOYER', 'RUNTIME', 'GATEWAY'] },
				},
				{
					path: eam.main_plan.remind,
					name: 'eam.main_plan.remind',
					component: () => import('/@/views/eam/maintainPlan/remind.vue'),
					meta: { title: '维保提醒', roles: ['MANAGER', 'DEPLOYER', 'RUNTIME', 'GATEWAY'] },
				},
			],
		},
		// 设备巡检
		{
			path: eam.device_inspection.index,
			name: 'eam.device_inspection.index',
			component: () => import('/@/views/eam/deviceInspection/index.vue'),
			redirect: eam.device_inspection.send,
			meta: { title: '设备巡检', roles: ['MANAGER', 'DEPLOYER', 'RUNTIME'] },
			children: [
				{
					path: eam.device_inspection.send,
					name: 'eam.device_inspection.send',
					component: () => import('/@/views/eam/deviceInspection/component/send.vue'),
					meta: { title: '我发起的', roles: ['MANAGER', 'DEPLOYER', 'RUNTIME'] },
				},
				{
					path: eam.device_inspection.worker,
					name: 'eam.device_inspection.worker',
					component: () => import('/@/views/eam/deviceInspection/component/work.vue'),
					meta: { title: '我执行的', roles: ['MANAGER', 'DEPLOYER', 'RUNTIME'] },
				},
			],
		},
		// 工单
		{
			path: eam.work_order.index,
			name: 'eam.work_order.index',
			component: () => import('/@/views/eam/workOrder/index.vue'),
			meta: { title: '工单', roles: ['DEPLOYER', 'MANAGER', 'RUNTIME'] },
			children: [
				{
					path: eam.work_order.send,
					name: 'eam.work_order.send',
					component: () => import('/@/views/eam/workOrder/send.vue'),
					meta: { title: '我发起的', roles: ['DEPLOYER', 'MANAGER', 'RUNTIME'] },
				},
				{
					path: eam.work_order.worker,
					name: 'eam.work_order.worker',
					component: () => import('/@/views/eam/workOrder/work.vue'),
					meta: { title: '我执行的', roles: ['DEPLOYER', 'MANAGER', 'RUNTIME'] },
				},
			],
		},
		// 库存管理
		{
			path: eam.inventory.index,
			name: 'eam.inventory.index',
			component: () => import('/@/views/eam/inventory/index.vue'),
			meta: { title: '库存管理', roles: ['DEPLOYER', 'MANAGER', 'RUNTIME'] },
			children: [
				{
					path: eam.inventory.category,
					name: 'eam.inventory.category',
					component: () => import('/@/views/eam/inventory/category.vue'),
					meta: { title: '库存类目', roles: ['DEPLOYER', 'MANAGER', 'RUNTIME'] },
				},
				{
					path: eam.inventory.outcome,
					name: 'eam.inventory.outcome',
					component: () => import('/@/views/eam/inventory/outcomeRecord.vue'),
					meta: { title: '出库记录', roles: ['DEPLOYER', 'MANAGER', 'RUNTIME'] },
				},
			],
		},
		// 工作日报
		{
			path: eam.report,
			name: 'eam.report',
			component: () => import('/@/views/eam/report/index.vue'),
			meta: { title: '工作日报', roles: ['DEPLOYER', 'MANAGER', 'RUNTIME'] },
		},
		// 站内信列表
		{
			path: eam.notify,
			name: 'eam.notify',
			component: () => import('/@/views/eam/notify/index.vue'),
			meta: { title: '站内信', roles: ['DEPLOYER', 'MANAGER', 'RUNTIME'] },
		},
	],
};
