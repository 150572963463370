import { RouteRecordRaw } from 'vue-router';
import { routePath } from '/@/constant/router';

export const fileManageRoute: RouteRecordRaw = {
	path: routePath.file_management,
	name: 'file_management',
	component: () => import('/@/views/fileManagement/index.vue'),
	meta: {
		title: '文件管理',
		roles: ['DEPLOYER', 'MANAGER', 'RUNTIME'],
		isHeadNav: true,
	},
};
